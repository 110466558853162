<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="">
		<van-pull-refresh class="jobHour" v-model="fresh" @refresh="onRefresh">
			<van-list offset="10" v-model="loadMore" :finished="loadFinish"
				:finished-text="jobData.length >= 5?'没有更多了':''" @load="scrollToLower">
				<div class="jobHour-top">
					<div class="jobHour-top-content">
						<span class="top-content-title">岗位总工时</span>
						<div class="top-content-unit">
							<span class="content-unit-num">{{manHourTotal}}</span>
							<span class="content-unit-text">h</span>
						</div>
					</div>
					<img class="jobHour-top-icon" src="@/assets/images/mobile/student/icon5.png" alt="">
				</div>
				<div class="jobHour-data">
					<jobList :importArea="'jobhour'" :jobList="jobData" :showEmpty="showEmpty" />
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>
<script>
	import jobList from "../components/jobList.vue";
	import {
		manhourListMb,
		manhourTotalMb,
	} from "@/api/mobile/manhour";
	export default {
		components: {
			jobList
		},
		data() {
			return {
				jobData: [],
				pageIndex: 1,
				pageSize: 6,
				totalCount: 0,
				fresh: false,
				loadMore: false,
				loadFinish: false,
				showEmpty: false,
				manHourTotal: 0,
				visitType: ""
			}
		},
		watch: {},
		methods: {
			onRefresh() {
				this.pageIndex = 1
				setTimeout(() => {
					this.jobData = []
					this.getJobData()
				}, 1000);
			},
			scrollToLower() {
				// 加载状态结束
				if (this.totalCount > this.pageSize && this.pageIndex > 1) {
					this.getJobData()
				}
			},
			// 岗位总工时统计
			getManhourTotal() {
				manhourTotalMb().then((res) => {
					if (res.code == 0) {
						this.manHourTotal = res.data.totalWorkingHour
					}
				})
			},
			getJobData() {
				return new Promise((resolve, reject) => {
					this.loadFinish = false
					this.showEmpty = false
					let params = {
						pageIndex: this.pageIndex,
						pageSize: this.pageSize,
						// workingHourStatus: 1
					}
					manhourListMb(params).then((res) => {
						// console.log("getJobData", res)
						if (res.code == 0) {
							if (this.pageIndex == 1) {
								this.fresh = false;
							}
							this.loadMore = false
							res.data.forEach(item => {
								this.$set(item, "status", 1)
							})
							this.jobData = this.jobData.concat(res.data)
							this.totalCount = res.page.totalCount
							if (this.jobData.length >= res.page.totalCount * 1) {
								this.loadFinish = true
							}
							if (this.jobData.length == 0) {
								this.showEmpty = true
							}
							this.pageIndex++
							resolve()
						} else {
							this.loadMore = false
							this.loadFinish = true
							this.fresh = false
							this.showEmpty = false
							reject()
						}
					}).catch((err) => {
						this.loadMore = false
						this.loadFinish = true
						this.fresh = false
						this.showEmpty = false
						reject()
					})
				})
			},
		},
		mounted() {
			this.visitType = this.$route.query.visitType
			if (this.visitType == 'confirm') {
				this.$toast.success("工时已确认")
			}
			this.getJobData()
			this.getManhourTotal()
		},
	}
</script>
<style lang="scss" scoped>
	.jobHour {
		height: 100%;
		display: flex;
		flex-direction: column;
		// padding: 0.28rem 0.28rem;
		// box-sizing: border-box;

		.jobHour-top {
			width: 6.94rem;
			height: 1.76rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-image: url("../../../assets/images/mobile/student/bg2.png");
			background-size: 100% 100%;
			background-repeat: no-repeat;
			margin: 0.28rem 0.28rem;
			box-sizing: border-box;

			.jobHour-top-content {
				display: flex;
				flex-direction: column;
				margin-left: 0.4rem;

				.top-content-title {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 0.4rem;
					color: #FFFFFF;
				}

				.top-content-unit {
					display: flex;
					align-items: flex-end;

					.content-unit-num {
						font-size: 0.56rem;
						font-family: DIN Alternate;
						font-weight: bold;
						line-height: 0.64rem;
						color: #FFFFFF;
					}

					.content-unit-text {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: bold;
						line-height: 0.4rem;
						color: rgba(255, 255, 255, 0.6);
					}
				}
			}

			.jobHour-top-icon {
				width: 1.06rem;
				height: 1.06rem;
				margin-right: 0.64rem;
			}
		}

		.jobHour-data {
			flex: 1;
		}
	}
</style>
